import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Icon, TextInput, Button } from '../../atoms'
import { APP_NAME } from '../../../const'
import { mediaPc } from '../../../lib/MediaQuery'
import { userType } from '../../../lib/UserType'
import searchGreen from '../../../assets/images/icon/search_green.png'
import logoBlack from '../../../assets/images/common/logo_black.png'
import mypageGreen from '../../../assets/images/icon/mypage_green.png'

import { CreatorProfileContext } from '../../../context'

type Props = {
  isLoggedIn: boolean
}

const StyledHeader = styled.header`
  width: 100vw;
  height: 60px;
  position: fixed;
  background-color: ${(props): string => props.theme.backgroundColors.bodyBg};
  z-index: 10;

  .inner {
    padding: 17px 3.4vw 0;
    display: flex;
    justify-content: space-between;

    .search {
      width: 30px;
      height: 30px;
      padding: 2px;

      .submit {
        width: 28px;
        height: 28px;
        background: url(${searchGreen}) center no-repeat;
        background-size: 24px;
        border-radius: 4px;
      }
    }

    .logo {
      width: 127px;
      height: 15px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 63.71px);

      &.logo-creator {
        position: static;
        margin: 0 0 0 4px;
        width: auto;

        & > a > img {
          width: 119px;
          margin-bottom: 7px;
        }

        & > .creator-sign-text {
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: 0.1em;
        }
      }
    }

    .login {
      position: relative;
      width: 72px;
      height: 30px;
      background: ${(props): string => props.theme.backgroundColors.primary};
      border-radius: 4px;
      text-align: center;
      transition: 0.3s all;

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: ${(props): string => props.theme.textColors.white};
        line-height: 30px;
        font-size: 12px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .mypage {
      width: auto;
      height: 30px;
      transition: 0.3s all;
      overflow: hidden;

      &:hover {
        opacity: 0.8;
      }

      .mypage-img {
        width: 32px;
        height: 32px;

        &.creator {
          border-radius: 50%;
        }
      }
    }
  }

  ${mediaPc`
    width: 100%;
    height: 70px;

    .inner {
      width: 1040px;
      max-width: 100%;
      margin: 0 auto;
      padding: 17px 20px 0;

      &.inner-creator {
        padding: 12px 20px 0;
        align-items: center;
      }

      .search {
        width: calc(100% - 300px);
        max-width: 612px;
        height: 40px;
        padding: 0;
        order: 1;
        display: flex;
        justify-content: space-between;

        .search-input {
          width: calc(100% - 52px);
          max-width: 560px;
          height: 40px;
          padding: 0 20px;
          color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
          font-size: 12px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
          border-radius: 4px;
          line-height: 40px;
    
          &::placeholder {
            color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.placeholder};
          }
        }
  
        .submit {
          width: 40px;
          height: 40px;
          background: url(${searchGreen}) center no-repeat ${(props: ThemeProps<DefaultTheme>): string =>
            props.theme.backgroundColors.primary};
          background-size: 27px;
          border-radius: 4px;
          transition: 0.3s all;
  
          &:hover {
            opacity: 0.8;
          }
        }
      }

      .logo {
        width: 150px;
        height: 46px;
        margin: 0;
        padding: 14px 0;
        position: static;
        order: 0;

        &.logo-creator {
          display: flex;
          align-items: center;

          margin: 0;

          & > a > img {
            width: 168px;
            margin-bottom: 0;
          }

          & > .creator-sign-text {
            font-size: 16px;
            line-height: 1.7;

            &::before {
              margin: 0 16px 0 19px;
              content: "";
              border-left: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.darkBlack};
            }
          }
        }
      }
  
      .login {
        position: relative;
        width: auto;
        height: 42px;
        padding: 5px 0;
        border-radius: 4px;
        text-align: center;
        order: 2;
        background: none;
  
        a {
          line-height: 30px;
          display: flex;
          color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textColors.black};
          font-size: 14px;
          transition: 0.3s all;
          position: static;
  
          &:hover {
            opacity: 0.8;
          }
        }
  
        .login-img {
          width: 32px;
          height: 32px;
          margin-right: 13px;
          object-fit: cover;
        }
      }
  
      .mypage {
        width: auto;
        height: 42px;
        padding: 5px 0;
        transition: 0.3s all;
        order: 2;
      }
    }

    .login-page-inner {
      padding: 17px 120px 0 20px;
      justify-content: flex-start;

      .search {
        margin-right: auto;
        width: calc(100% - 200px);
      }

      .logo {
        margin-right: auto;
      }
    }
  `}
`

export const Header: React.FC<Props> = ({ isLoggedIn }) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('')

  const history = useHistory()

  const creator = {
    icon_url: useContext(CreatorProfileContext).creator.icon_url,
  }

  const isLoginPage = window.location.pathname === '/login' || window.location.pathname === '/creator/login'

  const loginUrl = (): string => {
    if (userType() === 'creator') {
      return '/creator/login'
    }
    if (userType() === 'business') {
      return '/business/login'
    }
    return '/login'
  }

  const mypageUrl = (): string => {
    if (userType() === 'creator') {
      return '/creator/mypage'
    }
    if (userType() === 'business') {
      return '/business/mypage'
    }
    return '/mypage'
  }

  const homeUrl = (): string => {
    if (userType() === 'creator') {
      return '/creator'
    }
    if (userType() === 'business') {
      return '/business'
    }
    return '/'
  }

  const getSearchPagePathPerUser = (): string => {
    if (userType() === 'creator') {
      return `/creator/search`
    }
    if (userType() === 'business') {
      return `/business/creator/search`
    }
    return '/creators/search'
  }

  const onChangeSearchKeyword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(event.target.value)
  }

  const goToCreatorSearch = (): void => {
    history.push(`${getSearchPagePathPerUser()}`)
  }

  const goToCreatorSearchResult = (): void => {
    history.push(`${getSearchPagePathPerUser()}/result?keyword=${searchKeyword}`)
  }

  return (
    <StyledHeader>
      <div className={`inner ${userType() === 'creator' ? 'inner-creator' : ''} ${isLoginPage ? 'login-page-inner' : ''}`}>
        {userType() !== 'creator' && (
          <div className="search">
            <TextInput
              type="text"
              name="keyword"
              placeholder="クリエイターを検索"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
              className="pc search-input"
            />
            <Button className="sp submit" onClick={goToCreatorSearch}></Button>
            <Button className="pc submit" onClick={goToCreatorSearchResult}></Button>
          </div>
        )}
        <div className={userType() === 'creator' ? 'logo logo-creator' : 'logo'}>
          <Link to={homeUrl}>
            <Icon src={logoBlack} alt={APP_NAME} />
          </Link>
          {userType() === 'creator' && <div className="creator-sign-text">クリエイター管理画面（販売者ページ）</div>}
        </div>

        {!isLoggedIn && !isLoginPage && (
          <div className="login">
            <Link to={loginUrl}>
              <Icon src={mypageGreen} alt="ログイン" className="login-img pc" />
              <p>ログイン</p>
            </Link>
          </div>
        )}

        {isLoggedIn && (
          <div className="mypage">
            <Link to={mypageUrl}>
              <Icon
                src={userType() === 'creator' && creator.icon_url !== '' ? creator.icon_url : mypageGreen}
                alt="マイページ"
                className={userType() === 'creator' ? 'mypage-img creator' : 'mypage-img'}
              />
            </Link>
          </div>
        )}
      </div>
    </StyledHeader>
  )
}
