import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import moment from 'moment'
import { TextButton } from '../../atoms'
import { SalesPerMonth } from '../../../types/graphql'
import { mediaPc } from '../../../lib/MediaQuery'

type Props = {
  className?: string
  sales: SalesPerMonth[]
  noDataText: string
  onClick: (month: string) => void
}

const Table = styled.div`
  width: 100%;
  overflow-x: auto; /* 横スクロールを有効にする */
  background: #fff;
  border: 1px solid ${(props): string => props.theme.borderColors.black};
  .month {
    flex-grow: 3;
  }
  .sales-amount {
    flex-grow: 2;
    dd {
      text-align: right;
    }
  }
  .sales {
    flex-grow: 3;
    dd {
      text-align: right;
    }
  }
  .detail {
    flex-grow: 2;
    text-align: center;
    .detail-link {
      font-weight: 900;
      color: ${(props): string => props.theme.textColors.primary};
      cursor: pointer;
    }
  }
  .table-header {
    display: flex;
    border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
    div {
      height: auto;
      padding: 0 2.7vw;
      font-size: 3.2vw;
      font-weight: bold;
      color: ${(props): string => props.theme.textColors.black};
      line-height: 3em;
      border-right: 1px solid ${(props): string => props.theme.borderColors.black};
    }
  }
  .table-inner {
    width: auto;
    display: flex;
    dl {
      display: table; /* テーブルレイアウトを模倣 */
      width: 100%;
      min-width: 30vw;
      border-collapse: collapse;
      border-right: 1px solid ${(props): string => props.theme.borderColors.black};
      dt {
        height: auto;
        padding: 0 2.3vw;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
        font-size: 3.2vw;
        font-weight: bold;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        background: ${(props): string => props.theme.backgroundColors.bodyBg};
      }
      dd {
        height: auto;
        padding: 0 2.3vw;
        font-size: 3.2vw;
        font-weight: 900;
        color: ${(props): string => props.theme.textColors.black};
        line-height: 3em;
        border-bottom: 1px solid ${(props): string => props.theme.borderColors.black};
      }
      &:last-child,
      dd:last-child {
        border: none;
      }
    }
  }
  .no-data {
    padding: 2.3vw;
    font-size: 3.2vw;
  }
  ${mediaPc`
    width: 100%;
    overflow-x: inherit; /* 横スクロールを無効にする */
    dd{
      border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.borderColors.black};
    }
    .month {
      flex-grow: 4;
    }
    .sales-amount {
      flex-grow: 4;
      dd {
        text-align: left;
      }
    }
    .sales {
      flex-grow: 4;
    }
    .detail {
      width: 54px;
      flex-grow: 1;
      .detail-link {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .table-inner {
      dl {
        display: inline-block;
        min-width: inherit;
        border-collapse: inherit;
        dt {
          padding: 5px 12px 0;
          font-size: 14px;
          line-height: 28px;
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.backgroundColors.gray};
        }
        dd {
          padding: 6px 15px 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 35px;
        }
      }
    }
    .no-data {
      padding: 15px;
      font-size: 14px;
    }
  `}
`

export const SalesTable: React.FC<Props> = ({ className = '', sales, noDataText, onClick }) => {
  return (
    <Table className={className}>
      {sales.length !== 0 && (
        <div className="table-inner">
          <dl className="month">
            <dt>月</dt>
            {sales.map((sale, index) => (
              <dd key={index}>{moment(sale.month).format('YYYY年MM月')}</dd>
            ))}
          </dl>
          <dl className="sales-amount">
            <dt>販売件数</dt>
            {sales.map((sale, index) => (
              <dd key={index}>{sale.count}</dd>
            ))}
          </dl>
          <dl className="sales">
            <dt>売上(発注高)</dt>
            {sales.map((sale, index) => (
              <dd key={index}>{sale.sales.toLocaleString()}</dd>
            ))}
          </dl>
          <dl className="sales">
            <dt>売上(納品売上高)</dt>
            {sales.map((sale, index) => (
              <dd key={index}>{(sale.delivered_sales ?? 0).toLocaleString()}</dd>
            ))}
          </dl>
          <dl className="detail">
            <dt>詳細</dt>
            {sales.map((sale, index) => (
              <dd key={index}>
                <TextButton className="detail-link" onClick={() => onClick(sale.month)}>
                  内訳
                </TextButton>
              </dd>
            ))}
          </dl>
        </div>
      )}
      {/* 売上無い場合 styleは削除 */}
      {!sales.length && (
        <>
          <div className="table-inner">
            <dl className="month">
              <dt>月</dt>
            </dl>
            <dl className="sales-amount">
              <dt>販売件数</dt>
            </dl>
            <dl className="sales">
              <dt>売上(発注高)</dt>
            </dl>
            <dl className="sales">
              <dt>売上(納品売上高)</dt>
            </dl>
            <dl className="detail">
              <dt>詳細</dt>
            </dl>
          </div>
          <div className="no-data">
            <p>{noDataText}</p>
          </div>
        </>
      )}
    </Table>
  )
}
