import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { ProductPurchaseTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Creator,
  PublishedProduct,
  Paidy as PaidyType,
  RobotPayment as RobotPaymentType,
  useUserPurchaseProductMutation,
  usePublishedProductLazyQuery,
  useFavoriteCreatorMutation,
  useUnfavoriteCreatorMutation,
  useValidateUserTokenLazyQuery,
} from '../../../types/graphql'
import { paymentType } from '../../../types/myTypes'
import { useSetUserProfile } from '../../../lib/SetMyProfile'

type Props = {} & RouteComponentProps<{ id: string }>

export const UserProductPurchase: React.FC<Props> = (props) => {
  const [creator, setCreator] = useState<Creator>()
  const [product, setProduct] = useState<PublishedProduct>()
  const [payment, setPayment] = useState<'' | paymentType>('')
  const [paidyData, setPaidyData] = useState<PaidyType>()
  const [robotPaymentData, setRobotPaymentData] = useState<RobotPaymentType>()
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [goBackTimerId, setGoBackTimerId] = useState<NodeJS.Timeout | null>(null)

  const history = useHistory()
  const location = useLocation<{ from?: string }>()

  const [publishedProductLazyQuery] = usePublishedProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setProduct(data.PublishedProduct)
        setCreator(data.PublishedProduct?.creator ?? undefined)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [userPurchaseProductMutation] = useUserPurchaseProductMutation({
    onCompleted: async (data) => {
      setRobotPaymentData(data.UserPurchaseProduct.robot_payment)
      setPaidyData(data.UserPurchaseProduct.paidy)
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '商品を購入できません' })
      }

      const goBackTimerId = setTimeout(() => {
        history.goBack()
      }, 1000)
      setGoBackTimerId(goBackTimerId)
    },
  })

  const [validateUserTokenLazyQuery] = useValidateUserTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.ValidateUserToken === true) {
        // このmutationで商品を押さえるため、購入ボタン実行時ではなく、商品購入画面に遷移した時点で実行する
        // goBackTimerがループしてしまうので、tokenのチェックが通ってから実行する
        userPurchaseProductMutation({
          variables: {
            product_id: props.match.params.id,
          },
        })
      }
    },
    onError: () => {
      history.push({ pathname: '/login', state: { redirect: true, redirectFrom: location.pathname } })
    },
  })

  const [favoriteCreatorMutation] = useFavoriteCreatorMutation({
    onCompleted: () => {
      publishedProductLazyQuery({
        variables: {
          product_id: props.match.params.id,
        },
      })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り登録できませんでした' })
      }
    },
  })

  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      publishedProductLazyQuery({
        variables: {
          product_id: props.match.params.id,
        },
      })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const onChangePayment = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPayment(e.target.value as '' | paymentType)
  }

  const onClickPurchase = async (): Promise<void> => {
    try {
      if (payment === 'robotpayment') {
        if (robotPaymentData) {
          const form = document.createElement('form')
          form.action = robotPaymentData.credit_card_link
          form.method = 'GET'
          document.body.append(form)
          // formdata イベントに関数を登録(submit する直前に発火)
          form.addEventListener('formdata', (ev: FormDataEvent) => {
            const fd = ev.formData
            fd.set('aid', robotPaymentData.store_id) // 店舗ID
            fd.set('am', String(robotPaymentData.price)) // 商品金額
            fd.set('tx', String(robotPaymentData.tax)) // 税額
            fd.set('sf', String(robotPaymentData.shipping_fee)) // 送料
            fd.set('jb', robotPaymentData.job_type) // ジョブタイプ
            fd.set('cod', robotPaymentData.order_id) // 店舗オーダー番号
            fd.set('em', robotPaymentData.email) // Eメール
            fd.set('inm', robotPaymentData.product_name) // 商品名
            fd.set('iid2', robotPaymentData.product_id) // 商品番号
          })
          form.submit()
        } else {
          throw new Error()
        }
      } else if (payment === 'paidy') {
        if (paidyData) {
          const paidyConfig = {
            api_key: paidyData.public_key,
            closed: (callbackData: { status: string }) => {
              if (callbackData.status === 'authorized') {
                history.push('/purchase/success')
              } else {
                history.push(`/purchase/${props.match.params.id}/failure`)
              }
            },
          }
// @ts-expect-error
          const paidyHandler = Paidy.configure(paidyConfig)
          paidyHandler.launch(paidyData.payload)
        } else {
          throw new Error()
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      setFlashMessage({ type: 'systemError', message: '商品を購入できませんでした' })
    }
  }

  const onClickFavorite = (creatorId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    favoriteCreatorMutation({
      variables: {
        creator_id: creatorId,
      },
    })
  }
  const onClickUnfavorite = (creatorId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    unfavoriteCreatorMutation({
      variables: {
        creator_id: creatorId,
      },
    })
  }

  useSetUserProfile()

  // PaidyのCheckout JSスクリプト
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://apps.paidy.com/'
    script.type = 'text/javascript'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    publishedProductLazyQuery({
      variables: {
        product_id: props.match.params.id,
      },
    })
    validateUserTokenLazyQuery()
  }, [props.match.params.id, publishedProductLazyQuery, userPurchaseProductMutation, validateUserTokenLazyQuery])

  useEffect(() => {
    if (location.state) {
      // 商品購入失敗からの遷移
      if (location.state.from === 'purchaseFailure') {
        setFlashMessage({ type: 'systemError', message: '決済エラーが発生したため、お手数ですが、再度購入の手続きをお願いいたします。' })
      }
    }
  }, [location.state])

  // location.stateがセットされたままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (location.state) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  useEffect(() => {
    return () => {
      clearTimeout(goBackTimerId ?? undefined)
    }
  })

  return (
    <>
      <Meta title="商品の購入" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {creator && product && (
        <ProductPurchaseTpl
          creator={creator}
          product={product}
          payment={payment}
          onChangePayment={onChangePayment}
          onClickPurchase={onClickPurchase}
          onClickFavorite={onClickFavorite}
          onClickUnfavorite={onClickUnfavorite}
        />
      )}
    </>
  )
}
