import React, { useState, useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import moment from 'moment'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessTwoColumnLayout, CsvExportTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { CsvExportBy, FlashMessageType } from '../../../types/myTypes'
import {
  Affiliation,
  Creator,
  useAffiliationsInBusinessLazyQuery,
  useActiveCreatorsInAffiliationLazyQuery,
  useActiveCreatorsInBusinessLazyQuery,
  useGenerateTemporaryUrlForFilteredMonthlySalesLazyQuery,
  useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery,
} from '../../../types/graphql'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'

export const BusinessCsvExport: React.FC = () => {
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [months, setMonths] = useState<string[]>([])
  const [creators, setCreators] = useState<Creator[]>([])
  const [selectedAffiliationForExportByCreator, setSelectedAffiliationForExportByCreator] = useState<string>('')
  const [selectedAffiliationForExportByProduct, setSelectedAffiliationForExportByProduct] = useState<string>('')
  const [selectedMonthForExportByCreator, setSelectedMonthForExportByCreator] = useState<string>('')
  const [selectedMonthForExportByProduct, setSelectedMonthForExportByProduct] = useState<string>('')
  const [selectedCreatorForExportByProduct, setSelectedCreatorForExportByProduct] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [affiliationsInBusinessLazyQuery] = useAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setAffiliations(data.AffiliationsInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [activeCreatorsInAffiliationLazyQuery] = useActiveCreatorsInAffiliationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setCreators(data.ActiveCreatorsInAffiliation)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [activeCreatorsInBusinessLazyQuery] = useActiveCreatorsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setCreators(data.ActiveCreatorsInBusiness)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [generateTemporaryUrlForFilteredMonthlySalesLazyQuery] = useGenerateTemporaryUrlForFilteredMonthlySalesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        window.location.href = data.GenerateTemporaryUrlForFilteredMonthlySales
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [generateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery] = useGenerateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        window.location.href = data.GenerateTemporaryUrlForFilteredMonthlySalesByCreator
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const onSelectAffiliation = (e: React.ChangeEvent<HTMLSelectElement>, exportBy: CsvExportBy): void => {
    if (exportBy === 'creator') {
      setSelectedAffiliationForExportByCreator(e.target.value)
    }
    if (exportBy === 'product') {
      setLoading(true)
      setSelectedAffiliationForExportByProduct(e.target.value)
      setFlashMessage(null)

      // 所属の選択を変更するたび所属内のクリエイターを取得する
      if (e.target.value === '') {
        activeCreatorsInBusinessLazyQuery()
      } else {
        activeCreatorsInAffiliationLazyQuery({
          variables: {
            affiliation_id: e.target.value === '0' ? null : e.target.value,
          },
        })
      }

      setSelectedCreatorForExportByProduct('')
    }
  }

  const onSelectMonth = (e: React.ChangeEvent<HTMLSelectElement>, exportBy: CsvExportBy): void => {
    if (exportBy === 'creator') {
      setSelectedMonthForExportByCreator(e.target.value)
    }
    if (exportBy === 'product') {
      setSelectedMonthForExportByProduct(e.target.value)
    }
  }

  const onSelectCreator = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedCreatorForExportByProduct(e.target.value)
  }

  const exportCsv = (exportBy: CsvExportBy): void => {
    setLoading(true)
    setFlashMessage(null)
    if (exportBy === 'creator') {
      const hasAllAffiliation = selectedAffiliationForExportByCreator === ''
      generateTemporaryUrlForFilteredMonthlySalesByCreatorLazyQuery({
        variables: {
          input: {
            has_all_affiliation: hasAllAffiliation,
            affiliation_id: selectedAffiliationForExportByCreator,
            year_month: selectedMonthForExportByCreator,
          },
        },
      })
    }
    if (exportBy === 'product') {
      const hasAllAffiliation = selectedAffiliationForExportByProduct === '' && selectedCreatorForExportByProduct === ''
      generateTemporaryUrlForFilteredMonthlySalesLazyQuery({
        variables: {
          input: {
            has_all_affiliation: hasAllAffiliation,
            affiliation_id: selectedAffiliationForExportByProduct,
            creator_id: selectedCreatorForExportByProduct === '0' ? null : selectedCreatorForExportByProduct,
            year_month: selectedMonthForExportByProduct,
          },
        },
      })
    }
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    affiliationsInBusinessLazyQuery()
    activeCreatorsInBusinessLazyQuery()

    const monthsFromServiceStart = moment().diff(moment('202001'), 'months')
    const months = []
    for (let i = 0; i < monthsFromServiceStart + 1; i++) {
      months.push(moment('202001').add(i, 'months').format('YYYYMM'))
    }
    setMonths(months)
  }, [activeCreatorsInBusinessLazyQuery, affiliationsInBusinessLazyQuery])

  return isBrowser ? (
    <>
      <Meta title="CSV書き出し" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="csv">
        <CsvExportTpl
          affiliations={affiliations}
          months={months}
          creators={creators}
          selectedAffiliationForExportByCreator={selectedAffiliationForExportByCreator}
          selectedAffiliationForExportByProduct={selectedAffiliationForExportByProduct}
          selectedMonthForExportByCreator={selectedMonthForExportByCreator}
          selectedMonthForExportByProduct={selectedMonthForExportByProduct}
          selectedCreatorForExportByProduct={selectedCreatorForExportByProduct}
          onSelectAffiliation={onSelectAffiliation}
          onSelectMonth={onSelectMonth}
          onSelectCreator={onSelectCreator}
          exportCsv={exportCsv}
        />
      </BusinessTwoColumnLayout>
    </>
  ) : null
}
