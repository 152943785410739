import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { BusinessAffiliations } from '../../../components/organisms'
import { BusinessTwoColumnLayout, AffiliationRegistrationModalTpl } from '../../../components/templates'
import { useSetMyProfile } from '../../../lib/SetMyProfile'
import { useValidateBusinessUserToken } from '../../../lib/ValidateBusinessUserToken'
import { FlashMessageType } from '../../../types/myTypes'
import { Meta } from '../../../Meta'
import { Affiliation, usePagedAffiliationsInBusinessLazyQuery, useRegisterAffiliationMutation } from '../../../types/graphql'

export const BusinessAffiliation: React.FC = () => {
  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [newAffiliationName, setNewAffiliationName] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation<{ modalVisible: boolean }>()

  const [PagedAffiliationsInBusinessLazyQuery] = usePagedAffiliationsInBusinessLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setAffiliations(data.PagedAffiliationsInBusiness?.data ?? [])
        setLastPage(data.PagedAffiliationsInBusiness?.paginatorInfo.lastPage ?? 1)
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [registerAffiliationMutation] = useRegisterAffiliationMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setNewAffiliationName('')
        setFlashMessage({ type: 'success', message: '登録が完了しました' })

        PagedAffiliationsInBusinessLazyQuery({
          variables: {
            first: 20,
            page: currentPage,
          },
        })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const openAffiliationRegistrationModal = (): void => {
    setModalVisible(true)
  }
  const closeModal = (): void => {
    setModalVisible(false)
    setNewAffiliationName('')
  }

  const onChangeNewAffiliationName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewAffiliationName(event.target.value)
  }
  const onClickAffiliationRegistration = async (): Promise<void> => {
    setLoading(true)
    setFlashMessage(null)
    await registerAffiliationMutation({
      variables: {
        input: {
          name: newAffiliationName,
        },
      },
    })
  }

  // tokenが有効かチェック(useEffectの一番上に置く)
  useValidateBusinessUserToken()

  // ブラウザ更新時にアカウント情報を再取得する
  useSetMyProfile()

  useEffect(() => {
    if (location.state) {
      setModalVisible(location.state.modalVisible)
    }
  }, [location.state])

  // location.state.modalVisible = trueのままだとリロード時に毎回モーダルが表示されるのでreplace
  useEffect(() => {
    if (location.state && location.state.modalVisible === true) {
      history.replace({ ...location, state: undefined })
    }
  }, [history, location])

  useEffect(() => {
    const pageParam = new URLSearchParams(location.search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    setLoading(true)
    PagedAffiliationsInBusinessLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [PagedAffiliationsInBusinessLazyQuery, location.search])

  return (
    <>
      <Meta title="所属リスト一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <BusinessTwoColumnLayout currentTab="affiliation">
        <BusinessAffiliations
          affiliations={affiliations}
          currentPage={currentPage}
          lastPage={lastPage}
          openAffiliationRegistrationModal={openAffiliationRegistrationModal}
        />
      </BusinessTwoColumnLayout>
      {modalVisible && (
        <AffiliationRegistrationModalTpl
          closeModal={closeModal}
          newAffiliationName={newAffiliationName}
          onChangeNewAffiliationName={onChangeNewAffiliationName}
          onClickAffiliationRegistration={onClickAffiliationRegistration}
        />
      )}
    </>
  )
}
