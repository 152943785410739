import React from 'react'
import { PrivacyTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

export const Privacy: React.FC = () => {
  return (
    <>
      <Meta title="プライバシーポリシー" />
      <PrivacyTpl />
    </>
  )
}
