import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { UserMypageEditTpl, UserTwoColumnLayout } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { User, useUpdateUserAccountMutation } from '../../../types/graphql'
import { useSetUserProfile } from '../../../lib/SetMyProfile'
import { useValidateUserToken } from '../../../lib/ValidateUserToken'

import { UserProfileContext } from '../../../context'

export const UserMypageEdit: React.FC = () => {
  const userProfile = useContext(UserProfileContext)
  const [user, setUser] = useState<User>(userProfile.user)
  const [loading, setLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [updateUserAccountMutation] = useUpdateUserAccountMutation({
    onCompleted: (data) => {
      if (data.UpdateUserAccount?.is_change_request_email === true) {
        history.push({ pathname: '/mypage/edit/complete', state: { email: user.email } })
      } else {
        history.push({ pathname: '/mypage', state: { editComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickUpdateUserAccount = (): void => {
    updateUserAccountMutation({
      variables: {
        input: {
          name: user?.name,
          email: user?.email,
          twitter_username: user?.twitter_username,
        },
      },
    })
  }

  const onChangeState = (name: string, value: string): void => {
    setUser(user ? { ...user, [name]: value } : user)
  }

  useValidateUserToken()
  useSetUserProfile()

  return (
    <>
      <Meta title="アカウント情報編集" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserTwoColumnLayout isVisibleUserEditButton={false}>
        <UserMypageEditTpl user={user} onClickUpdateUserAccount={onClickUpdateUserAccount} onChangeState={onChangeState} />
      </UserTwoColumnLayout>
    </>
  )
}
