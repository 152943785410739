import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorEditTpl, CreatorTwoColumnLayout, CreatorIconTrimmingModalTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import { Creator, useCurrentCreatorUpdateAccountMutation } from '../../../types/graphql'

import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorProfileContext } from '../../../context'

export const CreatorMypageEdit: React.FC = () => {
  const [creator, setCreator] = useState<Creator>()
  const [iconFileName, setIconFileName] = useState<string>('')
  const [iconUrl, setIconUrl] = useState<string>('')
  const [iconBeforeTrimming, setIconBeforeTrimming] = useState<string>('')
  const [iconFileAfterTrimming, setIconFileAfterTrimming] = useState<File | null>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const history = useHistory()
  const creatorInitialState = useContext(CreatorProfileContext).creator

  const [currentCreatorUpdateAccountMutation] = useCurrentCreatorUpdateAccountMutation({
    onCompleted: (data) => {
      if (data.CurrentCreatorUpdateAccount?.is_change_request_email === true) {
        history.push({
          pathname: `/creator/mypage/edit/complete`,
          state: { email: data.CurrentCreatorUpdateAccount?.change_request_email },
        })
      } else {
        history.push({ pathname: `/creator/mypage`, state: { editComplete: true } })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onChangeState = (name: string, value: string): void => {
    setCreator(creator ? { ...creator, [name]: value } : creator)
  }
  const onClickUpdateButton = (): void => {
    currentCreatorUpdateAccountMutation({
      variables: {
        creator_id: creator?.id ?? '',
        input: {
          name: creator?.name ?? '',
          email: creator?.email ?? '',
          profile: creator?.profile ?? '',
          twitter_username: creator?.twitter_username,
          instagram_username: creator?.instagram_username,
          youtube_channel_url: creator?.youtube_channel_url,
          kindle_author_url: creator?.kindle_author_url,
          icon: iconFileAfterTrimming,
        },
      },
    })
  }

  const closeModal = (): void => {
    setModalVisible(false)
    setIconBeforeTrimming('')
  }

  const onIconLoad = (result: string, file: File): void => {
    setIconBeforeTrimming(result)
    setIconFileName(file.name)
    setModalVisible(true)
  }

  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    setCreator(creatorInitialState)
    setIconUrl(creatorInitialState.icon_url)
  }, [creatorInitialState])
  return (
    <>
      <Meta title="アカウント情報編集" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <CreatorTwoColumnLayout currentTab="creatorInfo" isVisibleCreatorEditButton={false}>
        {creator && (
          <CreatorEditTpl
            creator={creator}
            iconUrl={iconUrl}
            onClickUpdateButton={onClickUpdateButton}
            onChangeState={onChangeState}
            onIconLoad={onIconLoad}
          />
        )}
        {modalVisible && (
          <CreatorIconTrimmingModalTpl
            src={iconBeforeTrimming}
            iconFileName={iconFileName}
            closeModal={closeModal}
            setIconUrlAfterTrimming={(url: string) => {
              setIconUrl(url)
            }}
            setIconFileAfterTrimming={(file: File) => {
              setIconFileAfterTrimming(file)
            }}
          />
        )}
      </CreatorTwoColumnLayout>
    </>
  )
}
