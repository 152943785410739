import React from 'react'
import { PasswordSendComplete } from '../../../components/templates'
import { Meta } from '../../../Meta'

export const UserPasswordSendComplete: React.FC = () => {
  return (
    <>
      <Meta title="パスワード再設定用メール送信" />
      <PasswordSendComplete />
    </>
  )
}
