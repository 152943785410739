import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FlashMessage } from '../../../components/atoms'
import { PasswordRenew } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { validatePassword, validatePasswordConfirm, validatePasswordAndPasswordConfirm } from '../../../lib/Validate'
import { FlashMessageType } from '../../../types/myTypes'
import { useUpdatePasswordBusinessUserMutation } from '../../../types/graphql'

export const BusinessPasswordRenew: React.FC = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [passwordConfirmError, setPasswordConfirmError] = useState<string | null>(null)
  const [passwordMatchError, setPasswordMatchError] = useState<string | null>(null)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const history = useHistory()

  const [updatePasswordBusinessUserMutation] = useUpdatePasswordBusinessUserMutation({
    onCompleted: () => {
      history.push('/business/password/renew/complete')
    },
    onError: (e) => {
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '送信できませんでした' })
      }
    },
  })

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
    const passwordError: string | null = validatePassword(event.target.value)
    const passwordMatchError: string | null = validatePasswordAndPasswordConfirm(event.target.value, passwordConfirm)
    setPasswordError(null)
    setPasswordMatchError(null)
    if (passwordError) {
      setPasswordError(passwordError)
    } else if (passwordMatchError) {
      setPasswordMatchError(passwordMatchError)
    }
  }

  const onChangePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirm(event.target.value)
    const passwordConfirmError: string | null = validatePasswordConfirm(event.target.value)
    const passwordMatchError: string | null = validatePasswordAndPasswordConfirm(password, event.target.value)
    setPasswordConfirmError(null)
    setPasswordMatchError(null)
    if (passwordConfirmError) {
      setPasswordConfirmError(passwordConfirmError)
    } else if (passwordMatchError) {
      setPasswordMatchError(passwordMatchError)
    }
  }

  const renewPassword = async (): Promise<void> => {
    if (passwordError) {
      return
    }

    const params = new URLSearchParams(window.location.search)
    const token = params.get('token') || null
    const businessId = params.get('businessUserId') || null

    if (token === null || businessId === null) {
      return
    }
    setFlashMessage(null)
    await updatePasswordBusinessUserMutation({
      variables: {
        business_user_id: businessId,
        input: {
          token: token,
          password: password,
        },
      },
    })
  }

  return (
    <>
      <Meta title="パスワード再入力" />
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <PasswordRenew
        onClick={renewPassword}
        onChangePassword={onChangePassword}
        onChangePasswordConfirm={onChangePasswordConfirm}
        password={password}
        passwordConfirm={passwordConfirm}
        passwordError={passwordError}
        passwordConfirmError={passwordConfirmError}
        passwordMatchError={passwordMatchError}
      />
    </>
  )
}
