import React from 'react'
import { TermsOfSaleTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'

export const TermsOfSale: React.FC = () => {
  return (
    <>
      <Meta title="特定商取引法に基づく表記" />
      <TermsOfSaleTpl />
    </>
  )
}
