import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorLiveTalkTpl } from '../../../components/templates'

import { connect, Room, LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack, ConnectOptions } from 'twilio-video'

import { FlashMessageType, LiveTalkStatus } from '../../../types/myTypes'
import { useCreatorCreateVideoChatRoomTokenLazyQuery, useCreatorProductLazyQuery, Product } from '../../../types/graphql'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'

type Props = {} & RouteComponentProps<{ id: string }>

export const CreatorLiveTalk: React.FC<Props> = (props) => {
  const [room, setRoom] = useState<Room | null>(null)
  const [roomToken, setRoomToken] = useState<string>('')
  const [product, setProduct] = useState<Product>()
  const [localTracks, setLocalTracks] = useState<(LocalVideoTrack | LocalAudioTrack | null)[]>([])
  const [remoteTracks, setRemoteTracks] = useState<(RemoteVideoTrack | RemoteAudioTrack | null)[]>([])
  const [talkSeconds, setTalkSeconds] = useState<number>(0)
  const [liveTalkStatus, setLiveTalkStatus] = useState<LiveTalkStatus>()
  const [showFinishButton, setShowFinishButton] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const [creatorCreateVideoChatRoomTokenLazyQuery] = useCreatorCreateVideoChatRoomTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      if (data) {
        setRoomToken(data.CreatorCreateVideoChatRoomToken)
      }
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: e.message })
      }
      Sentry.captureException(e)
    },
  })

  const [creatorProductLazyQuery] = useCreatorProductLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      if (data.CreatorProduct) {
        setProduct(data.CreatorProduct)
      }
    },
    onError: (e) => {
      setLoading(false)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: e.message })
      }
      Sentry.captureException(e)
    },
  })

  const connectTwilioRoom = async (): Promise<void> => {
    try {
      setLiveTalkStatus('connecting')

      const connectOptionsForPc: ConnectOptions = {
        audio: true,
        video: { height: 720, frameRate: 24, width: (720 * 9) / 16 },
        bandwidthProfile: {
          video: {
            mode: 'grid',
          },
        },
        maxAudioBitrate: 16000,
        networkQuality: { local: 1, remote: 1 },
      }
      const connectOptionsForSp: ConnectOptions = {
        audio: true,
        video: { height: (640 * 9) / 16, frameRate: 24, width: 640 },
        bandwidthProfile: {
          video: {
            mode: 'grid',
            maxSubscriptionBitrate: 2500000,
          },
        },
        maxAudioBitrate: 16000,
        networkQuality: { local: 1, remote: 1 },
      }
      const room = await connect(roomToken, isMobile ? connectOptionsForSp : connectOptionsForPc)

      if (room === null) throw new Error('roomがnullです')

      setRoom(room)

      startLiveTalk(room)
    } catch (e) {
      Sentry.captureException(e)
      setFlashMessage({ type: 'systemError', message: 'ライブトークを開始できませんでした' })
    }
  }

  const startLiveTalk = (room: Room): void => {
    setLiveTalkStatus('talking')
    const currentDate = moment()
    const secondsLeftBeforeFinish = moment(product?.live_talk?.started_at)
      .add(product?.live_talk?.talk_minutes, 'minutes')
      .diff(currentDate, 'seconds')

    // 接続時間を考慮してトーク時間に10s追加
    setTalkSeconds(secondsLeftBeforeFinish + 10)

    const creatorParticipant = room.localParticipant
    const existingCreatorTracks = Array.from(creatorParticipant.tracks.values()).map((publication) => publication.track)
    const nonNullCreatorTracks = existingCreatorTracks.filter((track) => track !== null) as LocalVideoTrack[]
    setLocalTracks([...localTracks, ...nonNullCreatorTracks])

    room.on('trackSubscribed', (track) => {
      setRemoteTracks((tracks) => [...tracks, track as RemoteAudioTrack | RemoteVideoTrack])
    })
    room.on('participantDisconnected', () => {
      // ユーザーがリロードorタブ削除のunloadイベントでfinishLiveTalkした場合
      setRemoteTracks([])
    })
  }

  const finishLiveTalk = (): void => {
    room?.disconnect()
    setRoom(null)
    setLiveTalkStatus('finished')
  }

  const startShowFinishButtonTimer = async (talkSeconds: number): Promise<() => void> => {
    const milliSecondsLeftBeforeFinish = talkSeconds * 1000
    const timerId = setTimeout(() => {
      setShowFinishButton(true)
    }, milliSecondsLeftBeforeFinish)
    return () => {
      clearTimeout(timerId)
    }
  }

  const startFinishLiveTalkTimer = async (talkSeconds: number): Promise<() => void> => {
    // クリエイターが終了するボタンを押さなくても30s後に自動で終了する
    const milliSecondsLeftBeforeFinish = (talkSeconds + 30) * 1000
    const timerId = setTimeout(() => {
      finishLiveTalk()
    }, milliSecondsLeftBeforeFinish)
    return () => {
      clearTimeout(timerId)
    }
  }

  useValidateCreatorToken()

  useEffect(() => {
    creatorProductLazyQuery({
      variables: {
        product_id: props.match.params.id,
      },
    })
  }, [creatorProductLazyQuery, props.match.params.id])

  useEffect(() => {
    creatorCreateVideoChatRoomTokenLazyQuery({ variables: { product_id: props.match.params.id } })
  }, [creatorCreateVideoChatRoomTokenLazyQuery, props.match.params.id])

  useEffect(() => {
    if (!product || !roomToken) return
    setLoading(false)

    const currentDate = moment()
    const finishDate = moment(product?.live_talk?.started_at).add(product?.live_talk?.talk_minutes, 'minutes')
    if (currentDate.isAfter(finishDate.add(10, 'seconds'))) {
      setLiveTalkStatus('finished')
      return
    }

    setLiveTalkStatus('unStarted')

    const milliSecondsLeftBeforeStart = moment(product.live_talk?.started_at).diff(currentDate)

    const startTimerId = setTimeout(() => {
      connectTwilioRoom()
    }, milliSecondsLeftBeforeStart)

    return () => {
      clearTimeout(startTimerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, roomToken])

  useEffect(() => {
    if (liveTalkStatus === 'talking' && talkSeconds !== 0) {
      startShowFinishButtonTimer(talkSeconds)
      startFinishLiveTalkTimer(talkSeconds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveTalkStatus, talkSeconds])

  useEffect(() => {
    // タブを閉じた時にroom.disconnectする
    window.addEventListener('unload', finishLiveTalk)
    return () => {
      window.removeEventListener('unload', finishLiveTalk)
    }
  })

  return (
    <>
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} isHeaderInPage={false} />}
      {product && (
        <CreatorLiveTalkTpl
          localTracks={localTracks}
          remoteTracks={remoteTracks}
          liveTalkStatus={liveTalkStatus}
          product={product}
          talkSeconds={talkSeconds}
          showFinishButton={showFinishButton}
          finishLiveTalk={finishLiveTalk}
        />
      )}
    </>
  )
}
