import React, { useState, useEffect, useContext, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { isMobile } from 'react-device-detect'
import { FlashMessage, LoadingCircle } from '../../../components/atoms'
import { CreatorTwoColumnLayout, CreatorProductsTpl, DialogTpl } from '../../../components/templates'
import { Meta } from '../../../Meta'
import { FlashMessageType } from '../../../types/myTypes'
import {
  Product,
  PublishedProduct,
  usePagedPublishedProductsLazyQuery,
  usePagedCreatorDisabledProductsLazyQuery,
  usePagedCreatorReservedProductsLazyQuery,
  useCreatorFinishProductMutation,
  useCreatorDisableProductMutation,
  useCreatorDisplayProductMutation,
} from '../../../types/graphql'
import { useSetCreatorProfile } from '../../../lib/SetMyProfile'
import { useValidateCreatorToken } from '../../../lib/ValidateCreatorToken'
import { CreatorProfileContext } from '../../../context'

export const CreatorProducts: React.FC = () => {
  const [disabledProducts, setDisabledProducts] = useState<Product[]>([])
  const [publishedProducts, setPublishedProducts] = useState<PublishedProduct[]>([])
  const [reservedProducts, setReservedProducts] = useState<Product[]>([])
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [toEndDialogVisible, setToEndDialogVisible] = useState<boolean>(false)
  const creatorId = useContext(CreatorProfileContext).creator.id

  const [pagedPublishedProductsLazyQuery] = usePagedPublishedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setPublishedProducts(data.PagedPublishedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [pagedCreatorDisabledProductsLazyQuery] = usePagedCreatorDisabledProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setDisabledProducts(data.PagedCreatorDisabledProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [pagedCreatorReservedProductsLazyQuery] = usePagedCreatorReservedProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      if (data) {
        setReservedProducts(data.PagedCreatorReservedProducts?.data ?? [])
      }
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [creatorDisableProductMutation] = useCreatorDisableProductMutation({
    onCompleted: () => {
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を非表示にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [creatorFinishProductMutation] = useCreatorFinishProductMutation({
    onCompleted: () => {
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を販売終了にしました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const [creatorDisplayProductMutation] = useCreatorDisplayProductMutation({
    onCompleted: () => {
      fetchProducts()
      setFlashMessage({ type: 'success', message: '選択した商品を再表示しました' })
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '更新できませんでした' })
      }
    },
  })

  const onClickToDisabled = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisableProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }
  const onClickToEnd = (productId: string): void => {
    setToEndDialogVisible(true)
    setSelectedProductId(productId)
  }
  const onClickToEndDialogOk = (): void => {
    setToEndDialogVisible(false)
    setLoading(true)
    setFlashMessage(null)
    creatorFinishProductMutation({
      variables: {
        product_id: selectedProductId,
      },
    })
  }
  const onClickToEndDialogCancel = (): void => {
    setToEndDialogVisible(false)
  }
  const onClickToDisplay = (productId: string): void => {
    setLoading(true)
    setFlashMessage(null)
    creatorDisplayProductMutation({
      variables: {
        product_id: productId,
      },
    })
  }

  const fetchProducts = useCallback(() => {
    pagedPublishedProductsLazyQuery({
      variables: {
        creator_id: creatorId,
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
    pagedCreatorDisabledProductsLazyQuery({
      variables: {
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
    pagedCreatorReservedProductsLazyQuery({
      variables: {
        first: isMobile ? 4 : 8,
        page: 1,
      },
    })
  }, [creatorId, pagedPublishedProductsLazyQuery, pagedCreatorDisabledProductsLazyQuery, pagedCreatorReservedProductsLazyQuery])

  useValidateCreatorToken()
  useSetCreatorProfile()
  useEffect(() => {
    if (creatorId) {
      fetchProducts()
    }
  }, [
    creatorId,
    fetchProducts,
    pagedCreatorDisabledProductsLazyQuery,
    pagedCreatorReservedProductsLazyQuery,
    pagedPublishedProductsLazyQuery,
  ])

  return (
    <>
      <Meta title="商品一覧" />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {toEndDialogVisible && (
        <DialogTpl title="この商品を販売終了にしますか？" onClickOk={onClickToEndDialogOk} onClickCancel={onClickToEndDialogCancel}>
          販売終了にすると、この商品を再度販売できなくなります。再販する場合、新たに商品登録をお願い致します。
        </DialogTpl>
      )}
      <CreatorTwoColumnLayout currentTab="products">
        <>
          <CreatorProductsTpl
            publishedProducts={publishedProducts ?? []}
            reservedProducts={reservedProducts ?? []}
            disabledProducts={disabledProducts ?? []}
            onClickToDisabled={onClickToDisabled}
            onClickToEnd={onClickToEnd}
            onClickToDisplay={onClickToDisplay}
          />
        </>
      </CreatorTwoColumnLayout>
    </>
  )
}
